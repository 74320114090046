<template>
    <a-modal
        :visible="visible"
        :title="state.title"
        @ok="ConversionPoint"
        @cancel="handleModalCancel"
        width="50%"
        :destroy-on-close="true"
        :footer="null">
    
        <AForm
            ref="formRef"
            class="myform"
            :model="form"
            :rules="state.rules"
            @finish="handleOk"
            :wrapper-col="{ span: 14 }"
            :scroll-to-first-error="true">

            <!-- Jenis API -->
            <ARow class="form-row">
                <ACol :sm="24">
                    <AFormItem
                        label="Pilih Jenis API"
                        label-align="left"
                        :label-col="{ sm: { span: 10 }, lg: { span: 8 } , xxl: { span: 6 } }"
                        :wrapper-col="{ sm: { span: 24 - 10 }, lg: { span: 24 - 8 } , xxl: { span: 24 - 6 } }"
                        required
                        name="type"
                        has-feedback>
                        <FilterAtbusinessTypeApi
                            v-model:value="form.type"
                            :disabled="form.readOnly"
                            :mode="null"
                            style="width:100%;"/>
                    </AFormItem>
                </ACol>
            </ARow>

            <!-- pilih Fungsi -->
            <ARow class="form-row">
                <ACol :sm="24">
                    <AFormItem
                        label="Pilih Fungsi"
                        label-align="left"
                        :label-col="{ sm: { span: 10 }, lg: { span: 8 } , xxl: { span: 6 } }"
                        :wrapper-col="{ sm: { span: 24 - 10 }, lg: { span: 24 - 8 } , xxl: { span: 24 - 6 } }"
                        required
                        name="function"
                        has-feedback>
                        <FilterAtbusinessFunction
                            v-model:value="form.function"
                            :disabled="form.readOnly"
                            :mode="null"
                            style="width:100%;"/>
                    </AFormItem>
                </ACol>
            </ARow>

            <!-- URL -->
            <ARow class="form-row">
                <ACol :sm="24">
                    <AFormItem
                        label="URL"
                        label-align="left"
                        :label-col="{ sm: { span: 10 }, lg: { span: 8 } , xxl: { span: 6 } }"
                        :wrapper-col="{ sm: { span: 24 - 10 }, lg: { span: 24 - 8 } , xxl: { span: 24 - 6 } }"
                        required
                        name="url"
                        has-feedback>
                        <AInput
                            placeholder="http://example.com/"
                            v-model:value="form.url"
                            :disabled="form.readOnly"/>
                    </AFormItem>
                </ACol>
            </ARow>

            <!-- metode -->
            <ARow class="form-row">
                <ACol :sm="24">
                    <AFormItem
                        label="Metode"
                        label-align="left"
                        :label-col="{ sm: { span: 10 }, lg: { span: 8 } , xxl: { span: 6 } }"
                        :wrapper-col="{ sm: { span: 24 - 10 }, lg: { span: 24 - 8 } , xxl: { span: 24 - 6 } }"
                        required
                        name="method"
                        has-feedback>
                        <AInput
                            v-model:value="form.method"
                            :disabled="form.readOnly"/>
                    </AFormItem>
                </ACol>
            </ARow>

            <!-- token -->
            <ARow class="form-row">
                <ACol :sm="24">
                    <AFormItem
                        label="Token"
                        label-align="left"
                        :label-col="{ sm: { span: 10 }, lg: { span: 8 } , xxl: { span: 6 } }"
                        :wrapper-col="{ sm: { span: 24 - 10 }, lg: { span: 24 - 8 } , xxl: { span: 24 - 6 } }"
                        required
                        name="token"
                        has-feedback>
                        <AInput
                            v-model:value="form.token"
                            :disabled="form.readOnly"/>
                    </AFormItem>
                </ACol>
            </ARow>

            <!-- pilih Distributor Curah -->
            <ARow class="form-row">
                <ACol :sm="24">
                    <AFormItem
                        label="Pilih Distributor Curah"
                        label-align="left"
                        :label-col="{ sm: { span: 10 }, lg: { span: 8 } , xxl: { span: 6 } }"
                        :wrapper-col="{ sm: { span: 24 - 10 }, lg: { span: 24 - 8 } , xxl: { span: 24 - 6 } }"
                        required
                        name="vendor_id"
                        has-feedback>
                        <FilterDistributorCurah
                            v-model:value="form.vendor_id"
                            :disabled="form.readOnly"
                            :mode="null"
                            style="width:100%;"/>
                    </AFormItem>
                </ACol>
            </ARow>

            <ARow
                v-if="!form.readOnly"
                class="form-row"
                type="flex"
                justify="end">
                <ACol>
                    <AFormItem>
                        <a-button
                            type="primary"
                            html-type="submit"
                            :loading="form.busy"
                            :disabled="form.busy">{{
                            !form.id ? 'Simpan' : 'Perbarui'
                        }}</a-button>
                    </AFormItem>
                </ACol>
            </ARow>
        </AForm>

        <!-- <div v-if="form.errors.any()">
            <ASpace>
                <AAlert
                    v-for="(item, index) in form.errors.all()"
                    type="error"
                    :key="index"
                    :message="item.message"
                    banner
                    closable
                    @close="form.clear()" />
            </ASpace>
        </div> -->
    </a-modal>
</template>
<script>
import {
    defineComponent,
    onMounted,
    reactive,
    ref,
} from 'vue'
import FilterAtbusinessFunction from '@/components/filter/FilterAtbusinessFunction'
import FilterDistributorCurah from '@/components/filter/FilterDistributorCurah'
import FilterAtbusinessTypeApi from '@/components/filter/FilterAtbusinessTypeApi'
import Form from 'vform'
import { Modal, message } from 'ant-design-vue'

export default defineComponent({
    components: {
        FilterAtbusinessFunction,
        FilterDistributorCurah,
        FilterAtbusinessTypeApi,
    },
    props: {
        visible: [Boolean],
        item: {
            type: Object,
            default: () => ({
                id: null,
                readOnly: false,
            }),
        },
    },
    emits: ['handleOkConversionPoint', 'update:visible'],
    setup(props, { emit }) {
        const form = reactive(new Form({
            id: props.item.id,
            type: [],
            function: [],
            url: null,
            method: null,
            token: null,
            vendor_id: [],
        }))

        const formRef = ref();

        const state = reactive({
            title: 'Lihat Master API Distributor Curah',
            endpoint: '/api/vendor-rest-apies',
            data: [],
            page: 1,
            per_page: 1,
            total: 0,
            loading: false,
            rules: {
                type: [
                    {
                        required: true,
                        message: 'Pilih jenis API tidak boleh kosong!',
                    },
                ],
                function: [
                    {
                        required: true,
                        message: 'Pilih fungsi tidak boleh kosong!',
                    },
                ],
                url: [
                    {
                        required: true,
                        message: 'URL tidak boleh kosong!',
                    },
                ],
                method: [
                    {
                        required: true,
                        message: 'Metode tidak boleh kosong!',
                    },
                ],
                token: [
                    {
                        required: true,
                        message: 'Token tidak boleh kosong!',
                    },
                ],
                vendor_id: [
                    {
                        required: true,
                        message: 'Pilih distributor curah tidak boleh kosong!',
                    },
                ],
            },
        })

        const handleModalCancel = () => {
            emit('update:visible', false)
            emit('update:item', null)
        }

        const handleOk = async () => {

            // validation form
            await formRef.value.validate()
                .catch(() => {});

            if (!form.id) {
                form.post(state.endpoint)
                    .then(({ data }) => {
                        message.success('Berhasil menyimpan')

                        handleModalCancel()
                        emit('success', data)
                    })
                    .catch(e => message.error('Gagal menyimpan'))
            } else {
                form.put(`${state.endpoint}/${form.id}`)
                    .then(({ data }) => {
                        message.success('Berhasil memperbarui')

                        handleModalCancel()
                        emit('success', data)
                    })
                    .catch(e => message.error('Gagal menyimpan'))
            }

        }

        // handle func vue
        onMounted(() => {
            if (!form.id) {
                form.reset()
            } else {
                form.type = props.item.type
                form.function = props.item.function
                form.url = props.item.url
                form.method = props.item.method
                form.token = props.item.token
                form.vendor_id = props.item.vendor_id
            }

            form.readOnly = props.item.readOnly

            if (!props.item.readOnly) {
                state.title = `${!form.id ? 'Tambah' : 'Edit'} Master API Distributor Curah`
            }
        })

        return {
            formRef,
            handleOk,
            form,
            state,
            handleModalCancel,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/scss/form.scss';
</style>
